import revive_payload_client_4sVQNw7RlN from "/project/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/project/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/project/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/project/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/project/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/project/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/project/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/project/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/project/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/project/.nuxt/sentry-client-config.mjs";
import i18n_yfWm7jX06p from "/project/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/project/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import index_QtFNa6GtSJ from "/project/_plugins/i18n/index.ts";
import index_Kzvu99ioXf from "/project/_plugins/sdk/index.ts";
import document_SJEIRAtUwu from "/project/_plugins/document.js";
import index_gObnlbUC3g from "/project/_plugins/matchMedia/index.ts";
import index_6OGGLVqJ9L from "/project/_plugins/auth/index.ts";
import index_KgEMKQApVT from "/project/_plugins/dialog/index.ts";
import index_csBgwCUzg0 from "/project/_plugins/time/index.ts";
import index_NJEBZJIo0B from "/project/_plugins/analytics/index.ts";
import index_8gjXczfcd4 from "/project/_plugins/snacks/index.ts";
import index_yK2njzhkfO from "/project/_plugins/share/index.ts";
import index_poU5Vv1xYz from "/project/_plugins/guide/index.ts";
import index_UVJDjgVE2e from "/project/_plugins/ws/index.ts";
import index_fKSiRWPhD1 from "/project/_plugins/activations/index.js";
import app_client_osq0g64mXu from "/project/_plugins/app.client.js";
import index_fPISQw8NP2 from "/project/_plugins/scroll/index.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  index_QtFNa6GtSJ,
  index_Kzvu99ioXf,
  document_SJEIRAtUwu,
  index_gObnlbUC3g,
  index_6OGGLVqJ9L,
  index_KgEMKQApVT,
  index_csBgwCUzg0,
  index_NJEBZJIo0B,
  index_8gjXczfcd4,
  index_yK2njzhkfO,
  index_poU5Vv1xYz,
  index_UVJDjgVE2e,
  index_fKSiRWPhD1,
  app_client_osq0g64mXu,
  index_fPISQw8NP2
]