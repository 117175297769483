export const options = { showStepsCounter: false }
export const config = [
  {
    size: 'medium',
    scroll: { lock: true },
    delay: 1000,
    className: 'app-guide-text',
    overlay: {
      pointerEvents: 'all'
      // padding: 20,
    },
    title: 'Добро пожаловать в PARI Pass!',
    text: 'Это интерактивный ивент, где ты можешь зарабатывать игровые баллы и тратить их на крутые призы, например: ножи, арканы и фрибеты. Сейчас мы быстро объясним тебе, как все устроено!',
    amplitude: [
      'popup_open',
      {
        popup_name: 'app_guide',
        pop_text: 'Добро пожаловать в PARI Pass!'
      }
    ],
    buttons: [
      {
        text: 'Погнали',
        theme: 'accent',
        amplitude: [
          'button_click',
          {
            popup_name: 'app_guide',
            button_name: 'go',
            button_text: 'Погнали',
            pop_text: 'Добро пожаловать в PARI Pass!'
          }
        ]
      },
      {
        text: 'Пропустить знакомство',
        emit: 'skip',
        amplitude: [
          'button_click',
          {
            popup_name: 'app_guide',
            button_name: 'pass',
            button_text: 'Пропустить знакомство',
            pop_text: 'Добро пожаловать в PARI Pass!'
          }
        ]
      }
    ]
  },
  {
    size: 'medium',
    element: '[data-menu-id="tasks"]',
    scroll: { lock: true },
    className: 'app-guide-tasks',
    overlay: {
      pointerEvents: 'all',
      padding: 20
    },
    offset: { top: 20 },
    text: 'На этой странице ты видишь список всех доступных для выполнения заданий. Выполняй задания — получай PARI коины, все просто.',
    buttons: [
      { text: 'Понял, дальше', theme: 'accent' },
      { text: 'Пропустить знакомство', emit: 'skip' }
    ]
  },
  {
    size: 'medium',
    element: '#header-account-coins',
    className: 'app-guide-coins',
    overlay: {
      pointerEvents: 'all'
      // padding: 20,
    },
    text: 'Здесь отображается твой баланс PARI и Legacy коинов. Legacy коины — редкая валюта, которая выдается за выполнение особых заданий.',
    buttons: [
      { text: 'Отлично, дальше', theme: 'accent' },
      { text: 'Пропустить знакомство', emit: 'skip' }
    ]
  },
  // {
  //   size: 'medium',
  //   element: '#tab-code',
  //   position: 'left',
  //   className: 'app-guide-tab-code',
  //   overlay: {
  //     pointerEvents: 'all'
  //     // padding: 20,
  //   },
  //   text: 'Это меню кодов. Сюда ты сможешь ввести секретный код. Тут же можно получить подсказки о кодах: где они спрятаны и как их найти. За каждый введенный код ты получишь PARI Coins.',
  //   buttons: [
  //     { text: 'Ага, коды, понятно', theme: 'accent' },
  //     { text: 'Пропустить знакомство', emit: 'skip' }
  //   ]
  // },
  {
    size: 'medium',
    element: '[data-menu-id="profile"]',
    className: 'app-guide-menu-profile',
    overlay: {
      pointerEvents: 'all',
      padding: 20
    },
    offset: { top: 20 },
    text: 'Твой профиль. Полностью настрой свой аккаунт — тогда ты получишь доступ к игровым заданиям и будешь получать призы из магазина без задержек. Не забудь подключить уведомления в ВК и Telegram, чтобы быть в курсе всех событий ивента!',
    buttons: [
      { text: 'Хорошо', theme: 'accent' },
      { text: 'Пропустить знакомство', emit: 'skip' }
    ]
  },
  {
    size: 'medium',
    element: '[data-menu-id="shop"]',
    className: 'app-guide-shop',
    overlay: {
      pointerEvents: 'all',
      padding: 20
    },
    offset: { top: 20 },
    text: 'Здесь ты можешь обменять свои коины на призы. Обрати внимание — перед первой покупкой тебе необходимо выполнить особое задание на доступ к магазину. Заглядывай сюда почаще, магазин регулярно пополняется новыми товарами.',
    buttons: [
      { text: 'Понял-принял', theme: 'accent' },
      { text: 'Пропустить знакомство', emit: 'skip' }
    ]
  },
  {
    size: 'medium',
    element: '[data-menu-id="referrals"]',
    className: 'app-guide-referrals',
    overlay: {
      pointerEvents: 'all',
      padding: 20
    },
    offset: { top: 20 },
    text: 'Не имей 100 PARI коинов, а имей 100 друзей. Каждый принесет тебе по 10 000 PARI коинов, если ты пригласишь их в ивент по своей уникальной ссылке и друзья выполнят несколько заданий.',
    buttons: [
      { text: 'Все понятно, что есть еще?', theme: 'accent' },
      { text: 'Пропустить знакомство', emit: 'skip' }
    ]
  },
  {
    size: 'medium',
    element: '[data-menu-id="battlepass"]',
    className: 'app-guide-battlepass',
    overlay: {
      pointerEvents: 'all',
      padding: 20
    },
    offset: { top: 20 },
    text: 'Выполняй задания, получай опыт и апай уровни! За каждый пройденный уровень ты получишь награду: от коинов до фрибетов. Сможешь пройти весь Season Pass?',
    buttons: [
      { text: 'Конечно смогу! Поехали дальше', theme: 'accent' },
      { text: 'Пропустить знакомство', emit: 'skip' }
    ]
  },
  {
    size: 'medium',
    element: '[data-menu-id="fantasy"]',
    className: 'app-guide-fantasy',
    overlay: {
      pointerEvents: 'all',
      padding: 20
    },
    offset: { top: 20 },
    text: 'Мы запускаем Fantasy для всех крупных турниров. Собирай состав из 5-ти игроков на каждый день турнира, зарабатывай очки и попадай в топ лучших участников Fantasy — для первых мест мы приготовили самые крутые призы!',
    buttons: [
      { text: 'Понятно, едем дальше', theme: 'accent' },
      { text: 'Пропустить знакомство', emit: 'skip' }
    ]
  },
  {
    size: 'medium',
    element: '[data-menu-id="clans"]',
    className: 'app-guide-clans',
    overlay: {
      pointerEvents: 'all',
      padding: 20
    },
    offset: { top: 20 },
    text: 'Одна голова — хорошо, а пятьдесят — еще лучше. Создавай свой клан или вступай в существующий. Раз в две недели мы щедро награждаем участников топ-5 самых активных кланов.',
    buttons: [
      { text: 'Понятно', theme: 'accent' },
      { text: 'Пропустить знакомство', emit: 'skip' }
    ]
  },
  {
    size: 'medium',
    element: '#aside-footer-menu',
    position: 'left',
    offset: { top: -30 },
    className: 'app-guide-support',
    overlay: {
      pointerEvents: 'all'
      // padding: 20,
    },
    text: 'Обязательно прочитай FAQ — мы спрятали там несколько кодов. Если столкнешься с проблемами, то обращайся в саппорт.\n\nУдачи на ивенте!',
    buttons: [{ text: 'Пока', theme: 'accent' }]
  }
]
