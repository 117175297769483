import { defineNuxtPlugin } from '#app'
import { useSettingsStore } from '~/stores/common/settings'
import { useNotificationsStore } from '~/stores/common/notifications'
import { useGuideStore } from '@/stores/common/guide.js'
import {
  config as guideConfig,
  options as guideOptions
} from '@/_plugins/guide/configs/app'
import { useAccountStore } from '@/stores/account'
import number from '@/utils/number'
import { watch, computed } from 'vue'
import { useApiClient, setPublicKey } from '@/composables/api-client'
import cookie from 'js-cookie'

import appConfig from '@/app.config'

const NODE_ENV = process.env.NODE_ENV
const isDev = NODE_ENV === 'development'

export default defineNuxtPlugin(async nuxtApp => {
  const { $snacks, $time, $i18n, $dialog, $analytics, $guide, $sdk } = nuxtApp
  const config = useRuntimeConfig()

  if (location.search) {
    cookie.set('advertInfo', location.search, {
      domain: '.pari.ru',
      expires: 7
    })
  }

  setPublicKey(config.public.APP_PUBLIC_KEY)

  const settingsStore = useSettingsStore()
  const notificationsStore = useNotificationsStore()
  const guideStore = useGuideStore()
  const accountStore = useAccountStore()
  const apiClient = useApiClient()
  const isAuthorized = computed(() => accountStore.isAuthorized)
  const account = computed(() => accountStore.account)

  let alreadyProcessingError = false

  const authorization = async ({ options }) => {
    const token = await $sdk
      .module('auth')
      .then(({ getAuthorizationToken }) => getAuthorizationToken())

    if (token) {
      options.headers.Authorization = token
    }
  }

  apiClient.setAuthorization(authorization)

  nuxtApp.hook('api-client:error', error => {
    const method = error?.options?.method?.toUpperCase()
    if (['POST', 'PUT', 'DELETE'].includes(method)) {
      const data = error.response?._data
      const message = data?.message || data?.code || $i18n.t('error')
      $snacks.error(message)
    }
    if (
      error.response.status.toString() === '401' &&
      !alreadyProcessingError &&
      location.pathname !== '/' &&
      !appConfig.maintenance
    ) {
      alreadyProcessingError = true
      showSignIn()
        .then(() => {
          location.reload()
        })
        .catch(() => {
          alreadyProcessingError = false
          $sdk.module('auth').then(({ logout }) => logout())
        })
    }
  })

  settingsStore.fetchTime().then(time => $time.setTimestamp(time * 1000))

  function showGuide() {
    if (
      !$guide.active &&
      window.innerWidth > 570 &&
      !guideStore.guideIsFinished('welcome')
    ) {
      guideStore.setLocalStateAsFinished('welcome')
      return $guide.create(guideConfig, guideOptions)
    }
  }

  function showSignUp() {
    $dialog({
      content: defineAsyncComponent(
        () => import('~/components/account/sign-up.vue')
      ),
      wrapper: null,
      closeOnEsc: false,
      closeByOutsideClick: false,
      onClose: async () => {
        await showGuide()
        // showBonusGuide()
      }
    })
  }

  function showSignIn() {
    return new Promise((resolve, reject) => {
      $dialog({
        content: defineAsyncComponent(
          () => import('~/components/account/sign-in.vue')
        ),
        wrapper: null,
        fetchData: () =>
          $sdk.module('auth').then(({ fetchClients }) => fetchClients()),
        onClose: e => {
          if (e === true) {
            resolve()
          } else {
            reject(new Error('Unauthorized'))
            $analytics.amplitude('button_click', {
              popup_name: 'need_auth',
              button_name: 'close_popup'
            })
          }
        }
      })
    })
  }

  nuxtApp.hook('app:mounted', async () => {
    if (isDev) {
      const config = useRuntimeConfig()
      document.body.setAttribute('data-dev-env', config.public.APP_ENV)
      document.body.classList.add('dev')
    }

    watch(
      isAuthorized,
      async value => {
        if (value) {
          if (account.value?.profile && !account.value.profile.agree) {
            showSignUp()
          }

          // showBonusGuide()
          // Temporary off
          // notificationsStore.fetchUnreadCount()

          await Promise.allSettled([
            accountStore.fetchPrivilegeKeys(),
            $sdk
              .module('common')
              .then(({ getWSInstance }) => getWSInstance().authorize())
          ])
        } else {
          await $sdk
            .module('common')
            .then(({ getWSInstance }) => getWSInstance().logout())
        }
      },
      {
        immediate: true
      }
    )

    $sdk.module('common').then(({ getWSInstance }) => {
      const ws = getWSInstance()

      ws.addEventListener(
        'coinTransaction',
        ({ direction, amount, code, balance }) => {
          const message = `${number(amount)} ${code.toUpperCase()} Коинов`
          accountStore.setBalance(code, balance)
          if (direction === 'increase') {
            $snacks.increase(`+${message}`, { duration: 6000 })
          } else if (direction === 'decrease') {
            $snacks.decrease(message, { duration: 6000 })
          }
        }
      )

      ws.addEventListener(['notification'], ({ text }) => {
        notificationsStore.incUnread()
        $snacks.info(text, { duration: 5000 })
      })

      ws.connect()
    })

    $sdk.module('auth').then(({ getAccount }) => getAccount())

    nuxtApp.provide('appClient', {
      showSignIn
    })
  })

  useHead({
    script: [
      {
        children: `
        (function(a) {
          var b = a.createElement("script");
          b.async = 1;
          b.src = "//code.4hunqp.ru/";
          a = a.getElementsByTagName("script")[0];
          a.parentNode.insertBefore(b, a)
        })(document);
      `,
        type: 'text/javascript',
        id: 'advcakeAsync'
      }
    ]
  })
})
