import { Time } from './plugin'
import { defineNuxtPlugin, type NuxtApp } from '#app'

export default defineNuxtPlugin(nuxtApp => {
  type __NuxtApp = typeof nuxtApp & Pick<NuxtApp, '$i18n'>

  function instance(timestamp: number) {
    const locale = nuxtApp.$i18n
      ? (nuxtApp as __NuxtApp).$i18n.locale.value
      : 'en'
    return new Time({ timestamp, locale })
  }

  instance.setTimestamp = function (timestamp: number) {
    Time.setOffset(Date.now() - timestamp)
  }

  return {
    provide: {
      time: instance
    }
  }
})
